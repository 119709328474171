import './ProductUpload.scss';

import gsap from 'gsap';
import { useEffect } from 'react';

import Ably from 'assets/ably.png';
import Musinsa from 'assets/musinsa.png';
import Z from 'assets/z.png';

const ProductUpload = () => {
  useEffect(() => {
    gsap.to('.product-upload-inner h3', {
      scrollTrigger: {
        trigger: '.product-upload-sec h3',
        start: 'top bottom',
        toggleActions: 'play none none reset',
      },
      x: 0,
      opacity: 1,
      delay: 0.3,
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: `.product-upload-sec ul`,
        start: 'top bottom',
        toggleActions: 'play none none reset',
      },
    });

    tl.fromTo(
      '.product-upload-sec ul li',
      {
        scaleX: 0,
      },
      { scaleX: '100%', duration: 0.5, stagger: 0.3 },
    );
    tl.fromTo(
      '.product-upload-sec ul li > div:first-child > span, .product-upload-sec ul li > div:first-child > img',
      {
        x: -70,
        opacity: 0,
      },
      { x: 0, opacity: 1, delay: 0.5, duration: 0.3, stagger: 0.3 },
      0,
    );
    tl.fromTo(
      '.product-upload-sec ul li > div:last-child > span, .product-upload-sec ul li > div:last-child > img',
      {
        x: -70,
        opacity: 0,
      },
      { x: 0, opacity: 1, delay: 0.5, duration: 0.3, stagger: 0.3 },
      0,
    );
    tl.fromTo(
      '.product-upload-sec ul li > div:first-child > div > span',
      {
        x: -70,
        opacity: 0,
      },
      { x: 0, opacity: 1, delay: 0.5, duration: 0.3, stagger: 0.3 },
      0,
    );
    tl.fromTo(
      '.product-upload-sec ul li > div:last-child > div > span',
      {
        x: -70,
        opacity: 0,
      },
      { x: 0, opacity: 1, delay: 0.5, duration: 0.3, stagger: 0.3 },
      0,
    );
  }, []);

  return (
    <section id="introduction" className="product-upload-sec">
      <div className="product-upload-inner">
        <h3 className="fade-in-left">
          한달에 신상,
          <br />
          몇개나 업로드 하세요?
        </h3>
        <div className="caption">
          <div>
            <span aria-label="hidden"></span>
            <span>일 평균</span>
          </div>
          <div>
            <span aria-label="hidden"></span>
            <span>월 평균</span>
          </div>
        </div>
        <ul>
          <li>
            <div>
              <div>
                <span>5,000개</span>
              </div>
              <span>
                <img
                  src={Ably}
                  alt="Ably"
                  height={144}
                  width={28}
                  loading="lazy"
                />
              </span>
            </div>
            <div>
              <div>
                <span>150,000개</span>
              </div>
              <span>
                <img
                  src={Ably}
                  alt="Ably"
                  height={144}
                  width={28}
                  loading="lazy"
                />
              </span>
            </div>
          </li>
          <li>
            <div>
              <div>
                <span>10,000개</span>
              </div>
              <img src={Z} alt="Z" height={216} width={216} loading="lazy" />
            </div>
            <div>
              <div>
                <span>300,000개</span>
              </div>
              <img src={Z} alt="Z" height={216} width={216} loading="lazy" />
            </div>
          </li>
          <li>
            <div>
              <div>
                <span>957개</span>
              </div>
              <img
                src={Musinsa}
                alt="Musinsa"
                height={216}
                width={216}
                loading="lazy"
              />
            </div>
            <div>
              <div>
                <span>30,000개</span>
              </div>
              <img
                src={Musinsa}
                alt="Musinsa"
                height={216}
                width={216}
                loading="lazy"
              />
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default ProductUpload;
