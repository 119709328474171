import './Advantages.scss';

import gsap from 'gsap';
import { useEffect } from 'react';

import Advantage1 from 'assets/advantage1.svg';
import Advantage2 from 'assets/advantage2.svg';
import Advantage3 from 'assets/advantage3.svg';
import useFadeInUp from 'hooks/useFadeInUp';

const Advantages = () => {
  useFadeInUp('.advantages-inner ul');

  return (
    <section className="advantages-sec">
      <div className="advantages-inner">
        <ul className="fade-in-up">
          <li>
            <img
              src={Advantage1}
              alt=""
              width={400}
              height={320}
              loading="lazy"
            />
            <h4>간단한 가입절차</h4>
            <p>
              이메일 인증만 하면
              <br />
              누구나 클로즈잇 유저가 될 수 있어요
              <br />
              서비스가 마음에 드신다면
              <br />
              결제를 위한 추가 정보만 입력해주세요
            </p>
          </li>
          <li>
            <img
              src={Advantage2}
              alt=""
              width={400}
              height={320}
              loading="lazy"
            />
            <h4>복잡한 설정없이</h4>
            <p>
              측정을 위한 복잡한 사전 설정도
              <br />
              복종 분류도 필요하지 않아요
              <br />앱 설치 후 사진만 찍으면 끝!
            </p>
          </li>
          <li>
            <img
              src={Advantage3}
              alt=""
              width={400}
              height={320}
              loading="lazy"
            />
            <h4>언제 어디서든</h4>
            <p>
              줄자 없이 스마트폰 하나로
              <br />
              시간에 구애받지 않고
              <br />
              작업할 수 있어요
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Advantages;
