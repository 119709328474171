import './Join.scss';

import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import Checkbox from 'components/common/Checkbox';
import TextField from 'components/common/TextField';

type FormValues = {
  email: string;
  authNumber: string;
  password: string;
  passwordConfirmation: string;
  agreement1: boolean;
  agreement2: boolean;
  agreement3: boolean;
};

const Join = () => {
  const methods = useForm<FormValues>();
  const [step, setStep] = useState(0);

  const onSubmit = () => {
    window.location.href = 'https://clothit-admin.web.app/';
  };

  const verifyEmail = () => {
    const { email } = methods.getValues();
    if (!email) {
      return;
    }
    setStep(1);
    methods.reset({ email });
  };

  const verifyAuthNumber = () => {
    const { email, authNumber } = methods.getValues();
    if (!email || !authNumber) {
      return;
    }
    setStep(2);
    methods.reset({ email, authNumber });
  };

  return (
    <section className="join-sec">
      <div className="join-inner">
        <h2>간편 회원가입</h2>
        <p>이메일 인증으로 간편하게 클로즈잇 서비스에 가입하세요.</p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <TextField
              name="email"
              label="이메일"
              subLabel="인증받으실 메일을 입력해주세요."
              onChange={() => setStep(0)}
              renderSuffix={() => (
                <button
                  type="button"
                  className="input-btn"
                  onClick={verifyEmail}
                >
                  이메일 인증
                </button>
              )}
            />
            {step > 0 && (
              <>
                <TextField
                  name="authNumber"
                  label="인증번호입력"
                  type="password"
                  required="인증번호를 다시 입력해 주세요."
                  onChange={() => setStep(1)}
                  renderSuffix={() => (
                    <button
                      type="button"
                      className="input-btn"
                      onClick={verifyAuthNumber}
                    >
                      인증하기
                    </button>
                  )}
                />
                {step > 1 && (
                  <>
                    <TextField
                      name="password"
                      label="비밀번호"
                      type="password"
                      required="비밀번호가 일치하지 않습니다."
                    />
                    <TextField
                      name="passwordConfirmation"
                      label="비밀번호 재확인"
                      type="password"
                      required="비밀번호가 일치하지 않습니다."
                    />
                  </>
                )}
              </>
            )}
            <Checkbox
              name="agreement1"
              label="전체 동의"
              required="약관을 확인하고 동의를 해주세요."
            />
            <Checkbox
              name="agreement2"
              label="클로즈잇 서비스 이용약관에 동의합니다."
              required="약관을 확인하고 동의를 해주세요."
            />
            <Checkbox
              name="agreement3"
              label="개인정보 처리방침에 동의합니다."
              required="약관을 확인하고 동의를 해주세요."
            />
            <button type="submit" disabled={step !== 2}>
              가입하기
            </button>
            <Link to="/login">아이디가 이미 있으신가요?</Link>
          </form>
        </FormProvider>
      </div>
    </section>
  );
};

export default Join;
