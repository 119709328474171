import './App.scss';

import ReactGA from 'react-ga4';
import { Outlet } from 'react-router-dom';

import Footer from 'components/common/Footer';
import Header from 'components/common/Header';

const MEASUREMENT_ID = 'G-4GJMMEC1RS';
if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize(MEASUREMENT_ID);
  ReactGA.send('pageview');
}

function App() {
  return (
    <div className="App">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
