import './Process.scss';

import Triangle from 'assets/triangle.svg';
import useFadeInUp from 'hooks/useFadeInUp';

const Process = () => {
  useFadeInUp('.process-inner');

  return (
    <section className="process-sec">
      <div className="process-inner fade-in-up">
        <h3>누구나 쉽게 할 수 있어요</h3>
        <ol>
          <li>
            <span>1</span>
            <h4>앱설치</h4>
            <p>
              회원가입 후<br />
              클로즈잇 앱을 설치해주세요.
            </p>
          </li>
          <li className="separator">
            <img src={Triangle} alt="" width={23} height={30} loading="lazy" />
          </li>
          <li>
            <span>2</span>
            <h4>제품 촬영</h4>
            <p>
              상품을 평평하게 두고
              <br />
              앱으로 사진을 찍어주세요
              <br />
              메뉴얼을 참고하시면 더 쉬워요
            </p>
          </li>
          <li className="separator">
            <img src={Triangle} alt="" width={23} height={30} loading="lazy" />
          </li>
          <li>
            <span>3</span>
            <h4>조견표 다운로드</h4>
            <p>
              사전에 고른 템플릿으로
              <br />
              오랜 기다림 없이
              <br />
              조견표를 다운받을 수 있어요
            </p>
          </li>
        </ol>
      </div>
    </section>
  );
};

export default Process;
