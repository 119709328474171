import './Header.scss';

import classNames from 'classnames';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Logo from 'assets/logo.svg';
import FakeLink from './FakeLink';

const Header = () => {
  const navigate = useNavigate();
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen((open) => !open);
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  const navigateToLogin = () => navigate('/login');

  const navigateToJoin = () => {
    closeNav();
    navigate('/join');
  };

  return (
    <header className="header">
      <div className="header-left">
        <h1 aria-label="Cloth it">
          <Link to="/" state="cover" onClick={closeNav}>
            <img src={Logo} alt="Cloth it" width={145} height={32} />
          </Link>
        </h1>
        <nav className={classNames({ active: navOpen })}>
          <ul>
            <li>
              <Link to="/" state="introduction" onClick={closeNav}>
                서비스소개
              </Link>
            </li>
            <li>
              <Link to="/" state="download" onClick={closeNav}>
                앱 다운로드
              </Link>
            </li>
            <li>
              <Link to="/" state="pricing" onClick={closeNav}>
                가격안내
              </Link>
            </li>
            <li>
              <FakeLink onClick={navigateToJoin}>회원가입</FakeLink>
            </li>
          </ul>
        </nav>
      </div>
      <div className="header-right">
        <button type="button" onClick={navigateToLogin}>
          로그인
        </button>
        <button type="button" onClick={navigateToJoin}>
          회원가입
        </button>
      </div>
      <button
        type="button"
        aria-label="nav-button"
        className={classNames('nav-toggle', { active: navOpen })}
        onClick={toggleNav}
      >
        <i>
          <div className="top"></div>
          <div className="middle-1"></div>
          <div className="middle-2"></div>
          <div className="bottom"></div>
        </i>
      </button>
    </header>
  );
};

export default Header;
