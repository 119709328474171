import './Categories.scss';

import useFadeInUp from 'hooks/useFadeInUp';

const Categories = () => {
  useFadeInUp('.categories-inner > div');

  return (
    <section className="categories-sec">
      <div className="categories-inner">
        <div className="blur fade-in-up">
          <h3>
            상품의 디자인이 다양한 만큼
            <br />
            복종 분류도 다양해야겠죠?
          </h3>
          <p>
            국내 최다 복종 이미지 보유
            <br />
            (현재 40종 이상 상시 추가 예정)
          </p>
        </div>
      </div>
    </section>
  );
};

export default Categories;
