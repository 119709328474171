import './NewsletterModal.scss';

import { FormProvider, useForm } from 'react-hook-form';
import ReactModal from 'react-modal';

import Checkbox from 'components/common/Checkbox';
import TextField from '../common/TextField';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

ReactModal.setAppElement('#root');

const NewsletterModal = ({ isOpen, onClose }: Props) => {
  const methods = useForm();

  const onSubmit = () => {
    onClose();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName="overlay"
      className="newsletter-modal"
      onRequestClose={onClose}
    >
      <button
        type="button"
        className="close"
        aria-label="close"
        onClick={onClose}
      ></button>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <h3>사이즈톡 뉴스레터 신청하기</h3>
          <p>매주 OOO한 소식을 전해드릴께요.</p>
          <TextField
            name="name"
            label="이름"
            subLabel="(선택) 이름을 입력해주세요."
          />
          <TextField
            name="phone"
            label="휴대폰 번호"
            subLabel="(선택) 연락 받으실 번호를 입력해주세요."
            numeric
          />
          <TextField
            required="이메일을 다시 입력해주세요."
            name="email"
            label="이메일"
            subLabel="인증받으실 메일을 입력해주세요."
          />
          <TextField
            name="shoppingMallName"
            label="쇼핑몰 상호명"
            subLabel="(선택) 사업자 등록증에 명시 되어있는 쇼핑몰 명칭을 입력해주세요."
          />
          <TextField
            name="shoppingMallUrl"
            label="쇼핑몰 URL"
            subLabel="(선택) 쇼핑몰 URL을 입력해주세요."
          />
          <TextField
            name="salesAmount"
            label="매출규모"
            subLabel="(선택) 사업장 매출규모를 입력해주세요. (연)단위"
          />
          <TextField
            name="employeesCount"
            label="직원수"
            subLabel="(선택) 사업장 직원수를 입력해주세요."
          />
          <TextField
            name="companyName"
            label="회사명"
            subLabel="(선택) 사업자 등록증에 명시되어 있는 회사명을 입력해주세요."
          />
          <Checkbox
            name="marketingAgreement"
            label="(선택) 클로즈잇 마케팅 수신동의"
          />
          <button type="submit">사이즈톡 소식받기</button>
        </form>
      </FormProvider>
    </ReactModal>
  );
};

export default NewsletterModal;
