import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Advantages from 'components/main/Advantages';
import AppStores from 'components/main/AppStores';
import Categories from 'components/main/Categories';
import Cover from 'components/main/Cover';
import Customers from 'components/main/Customers';
import Design from 'components/main/Design';
import OthersServices from 'components/main/OthersServices';
import Pricing from 'components/main/Pricing';
import Process from 'components/main/Process';
import ProductUpload from 'components/main/ProductUpload';
import Slogan from 'components/main/Slogan';

const useScrollOnLocationChange = () => {
  const location = useLocation();

  // smoth scroll
  useEffect(() => {
    const scrollTo = () => {
      if (!location.state) {
        return;
      }

      document
        .querySelector(`#${location.state as string}`)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    scrollTo();
  }, [location]);
};

const Main = () => {
  useScrollOnLocationChange();

  return (
    <>
      <Cover />
      <ProductUpload />
      <Slogan />
      <Advantages />
      <Process />
      <div className="fade-in-up">
        <AppStores />
        <Design />
        <Categories />
        <Customers />
        <Pricing />
        <OthersServices />
      </div>
    </>
  );
};

export default Main;
