import './AppStores.scss';

import AppStore from 'assets/appstore.svg';
import GooglePlay from 'assets/googleplay.svg';
import Logo from 'assets/logo.svg';
import useFadeInUp from 'hooks/useFadeInUp';

const AppStores = () => {
  useFadeInUp('.App > .fade-in-up');

  return (
    <section id="download" className="appstores-sec">
      <div>
        <div className="appstores-inner">
          <img
            src={Logo}
            alt="Cloth it"
            width={145}
            height={32}
            loading="lazy"
          />
          <p>
            회원 전용 앱으로 가입 승인 후에 이용이 가능해요.
            <br />앱 실행 전 꼭 회원 가입 신청을 해주세요.
          </p>
          <div className="appstores">
            <a href="">
              <img
                src={AppStore}
                alt="App Store"
                width={208}
                height={58}
                loading="lazy"
              />
            </a>
            <a href="">
              <img
                src={GooglePlay}
                alt="Google Play"
                width={208}
                height={58}
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppStores;
