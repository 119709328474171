import './TextField.scss';

import classNames from 'classnames';
import { ChangeEvent, InputHTMLAttributes, ReactNode } from 'react';
import { RegisterOptions, useController } from 'react-hook-form';

import { ReactComponent as ErrorIcon } from 'assets/error.svg';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  label: string;
  subLabel?: string;
  name: string;
  required?: any;
  numeric?: boolean;
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  renderSuffix?: () => ReactNode;
  onChange?: (e: any) => void;
}

export const numericRegex = /[^0-9-+]/g;

const TextField = ({
  className,
  label,
  subLabel,
  name,
  required,
  type = 'text',
  rules,
  numeric,
  renderSuffix,
  onChange: onChangeCallback = () => {},
  ...rest
}: Props) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules: { ...rules, required },
    defaultValue: '',
  });

  const onBlur = () => {
    field.onBlur();
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!numeric) {
      field.onChange(e);
      onChangeCallback(e);
    } else {
      const { value } = e.target;
      if (!value.match(numericRegex)) {
        field.onChange(value);
        onChangeCallback(value);
      }
    }
  };

  return (
    <>
      <div className={classNames('form-group', { error }, className)}>
        <label htmlFor="">
          {label}
          <span className="sub-label">{subLabel}</span>
        </label>
        <div className="form-wrapper">
          <input
            ref={field.ref}
            className="text-field"
            type={type}
            onBlur={onBlur}
            autoComplete="off"
            value={field.value}
            onChange={onChange}
            {...rest}
          />
          {error && <ErrorIcon />}
          {renderSuffix && !error && renderSuffix()}
        </div>
        {error && <span className="error-message">{error?.message}</span>}
      </div>
    </>
  );
};

export default TextField;
