import './index.scss';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { polyfill } from 'seamless-scroll-polyfill';

import Join from 'pages/Join';
import Login from 'pages/Login';
import Main from 'pages/Main';
import ResetPassword from 'pages/ResetPassword';
import App from './App';
import reportWebVitals from './reportWebVitals';

gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});
gsap.defaults({ duration: 0.3 });

// safari smoth scroll polyfill
polyfill();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/join" element={<Join />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route index element={<Main />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
