import './Cover.scss';

import gsap from 'gsap';
import { useEffect } from 'react';

import CoverImage from 'assets/cover.png';
import FreeImage from 'assets/free.svg';

const Cover = () => {
  useEffect(() => {
    gsap.to('.cover-inner > div:first-child', { y: 0, opacity: 1 });
  }, []);

  return (
    <section id="cover" className="cover-sec">
      <div className="cover-inner">
        <div className="fade-in-up">
          <h2>
            사이즈 자동화 솔루션
            <br />
            클로즈잇
          </h2>
          <p>
            사이즈 재고 조견표 만들고, <br className="sm" />
            반복되는 단순업무에 지치셨나요?
            <br />
            저희가 도와드릴게요.
            <br className="sm" /> 셀러님들은 매출에 집중하세요
          </p>
        </div>
        <div className="free-tag-container">
          <a href="" className="free-tag">
            <img src={FreeImage} alt="" width={67} height={58} />
            <span>
              무료로
              <br />
              시작하기
            </span>
          </a>
        </div>
        <img
          className="cover-img"
          src={CoverImage}
          alt=""
          width={1218}
          height={1102}
        />
      </div>
    </section>
  );
};

export default Cover;
