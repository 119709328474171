import './Login.scss';

import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import TextField from 'components/common/TextField';

type FormValues = {
  email: string;
  password: string;
};

const Login = () => {
  const methods = useForm<FormValues>();
  const navigate = useNavigate();

  const onSubmit = () => {
    window.location.href = 'https://clothit-admin.web.app/';
  };

  const navigateToJoin = () => navigate('/join');

  return (
    <section className="login-sec">
      <div className="login-inner">
        <h2>서비스 로그인</h2>
        <p>가입하신 이메일 주소와 비밀번호를 입력해주세요</p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <TextField
              required="이메일을 다시 입력해주세요."
              name="email"
              label="이메일"
            />
            <TextField
              required="비밀번호를 다시 입력해 주세요."
              name="password"
              label="비밀번호"
              type="password"
            />
            <div className="form-actions">
              <button type="submit">로그인</button>
              <button type="button" onClick={navigateToJoin}>
                회원가입
              </button>
            </div>
            <Link className="reset-password" to="/reset-password">
              비밀번호가 생각나지 않나요?
            </Link>
          </form>
        </FormProvider>
      </div>
    </section>
  );
};

export default Login;
