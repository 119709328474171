import { forwardRef, MouseEvent, ReactNode } from 'react';

interface Props {
  className?: string | null;
  onClick?: () => void;
  onMouseMove?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: ReactNode;
}

const FakeLink = forwardRef<HTMLAnchorElement, Props>(
  ({ className = null, onClick = () => {}, children = null, ...rest }, ref) => {
    const onFakeLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onClick();
    };

    return (
      <a
        ref={ref}
        href="#"
        className={className as string}
        onClick={onFakeLinkClick}
        {...rest}
      >
        {children}
      </a>
    );
  },
);

FakeLink.displayName = 'FakeLink';

export default FakeLink;
