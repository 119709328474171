import './Slogan.scss';

import gsap from 'gsap';
import { useEffect } from 'react';

import useFadeInUp from 'hooks/useFadeInUp';

const Slogan = () => {
  useFadeInUp('.slogan-inner .p-1');
  useFadeInUp('.slogan-inner .p-3');

  useEffect(() => {
    gsap.to('.slogan-inner div.fade-in-up', {
      scrollTrigger: {
        trigger: '.slogan-inner div.fade-in-up',
        start: '-200 bottom',
        toggleActions: 'play none none reset',
      },
      y: 0,
      opacity: 1,
      delay: 0.3,
    });
  }, []);

  return (
    <section className="slogan-sec">
      <div className="slogan-inner">
        <div className="line">
          <div></div>
          <span></span>
        </div>
        <p className="p-1 fade-in-up">
          매일매일 쏟아지는 신상,
          <br />
          사이즈 재느라 힘드시지 않으신가요?
        </p>
        <div className="line">
          <span></span>
          <div></div>
        </div>
        <div className="fade-in-up">
          <div aria-label="hidden"></div>
          <div aria-label="hidden"></div>
          <div aria-label="hidden"></div>
          <div aria-label="hidden"></div>
          <p className="p-2">이 옷들은 언제 다재!</p>
        </div>
        <div className="line">
          <div></div>
          <span></span>
        </div>
        <p className="p-3 fade-in-up">
          클로즈잇이 단순업무로
          <br />
          낭비되는 시간을 찾아드려요
        </p>
      </div>
    </section>
  );
};

export default Slogan;
