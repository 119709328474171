import './Design.scss';

import useFadeInUp from 'hooks/useFadeInUp';

const Design = () => {
  useFadeInUp('.design-sec h3');

  return (
    <section className="design-sec">
      <div className="design-inner">
        <div className="blur">
          <h3 className="fade-in-up">
            다양한 템플릿 디자인으로
            <br />
            쇼핑몰의 개성을 뽐내보세요
          </h3>
        </div>
      </div>
    </section>
  );
};

export default Design;
