import './Pricing.scss';

const Pricing = () => {
  return (
    <section id="pricing" className="pricing-sec">
      <div className="pricing-inner">
        <div className="pricing-header">
          <h3>서비스 요금</h3>
          <p>셀러님에게 맞는 요금제를 골라보세요</p>
        </div>
        <p className="vat-note">{'[VAT 별도]'}</p>
        <div className="pricing-list">
          <div className="pricing-card">
            <div className="pricing-card-header">
              <h4>Demo</h4>
            </div>
            <div className="pricing-card-content">
              <span className="pricing">
                <strong>무료체험</strong>/2주간
              </span>
              <ul className="benefits-list">
                <li>라이센스 1명</li>
                <li>10건 / 월별</li>
                <li>이미지 다운로드 1회 / 제품 당</li>
                <li>1GB 저장 용량 제공</li>
              </ul>
            </div>
          </div>
          <div className="pricing-card">
            <div className="pricing-card-header">
              <h4>Basic | 연간</h4>
            </div>
            <div className="pricing-card-content">
              <span className="pricing">
                <strong>89,000원</strong>
                <span>/129,000원</span>
              </span>
              <p className="pricing-card-vat">
                VAT 별도 | 결제 플랜, 실 입금 금액
                <br />= 플랜 가격*10%
              </p>
              <ul className="benefits-list">
                <li>라이센스 1명</li>
                <li>140건 / 월별</li>
                <li>이미지 다운로드 무제한 / 제품 당</li>
              </ul>
            </div>
          </div>
          <div className="pricing-card">
            <div className="pricing-card-header">
              <h4>Premium | 연간</h4>
            </div>
            <div className="pricing-card-content">
              <span className="pricing">
                <strong>180,000원</strong>
                <span>/229,000원</span>
              </span>
              <p className="pricing-card-vat">
                VAT 별도 | 결제 플랜, 실 입금 금액
                <br />= 플랜 가격*10%
              </p>
              <ul className="benefits-list">
                <li>라이센스 3명</li>
                <li>550건 / 월별</li>
                <li>이미지 다운로드 무제한 / 제품 당</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
