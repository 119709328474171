import './Footer.scss';

import Logo from 'assets/logo.svg';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-inner">
        <img src={Logo} alt="Cloth it" width={145} height={32} loading="lazy" />
        <div>
          <p>
            (주)아이딕션 | 34047 대전시 유성구 유성대로 <br className="sm" />
            1689번길 70, 3동 306호 │ <br className="sm" />
            사업자번호 160-87-01035 │ 대표번호 1533-1256 │ <br className="sm" />
            제휴문의 clothit@idic.io
            <span> | </span>
            <br />
            Copyright © IDICTION . All rights reserved.
          </p>
          <ul>
            <li>
              <a href="">개인정보취급방침</a>
            </li>
            <li>
              <a href="">법적고지</a>
            </li>
            <li>
              <a href="">이메일무단수집거부</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
