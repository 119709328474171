import './OthersServices.scss';

import { useState } from 'react';

import Service1 from 'assets/service1.png';
import Service2 from 'assets/service2.png';
import Service3 from 'assets/service3.png';
import NewsletterModal from './NewsletterModal';

const OthersServices = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const onOpenModal = () => {
    setModalOpen(true);
    document.body.style.overflow = 'hidden';
  };
  const onCloseModal = () => {
    setModalOpen(false);
    document.body.style.overflow = '';
  };

  return (
    <>
      <section className="others-services-sec">
        <div className="others-services-inner">
          <h3>
            똑 부러진 셀러님,
            <br />
            이런 서비스도 써보세요
          </h3>
          <ul>
            <li>
              <img
                src={Service1}
                alt=""
                width={400}
                height={400}
                loading="lazy"
              />
              <div className="texts-content">
                <p>
                  더 편한 판매를 위한 서비스
                  <br />
                  사이즈 추천, 사이즈 톡
                </p>
                <p>
                  끊임 없는 사이즈 문의, 이제 그만
                  <br />
                  의류 사이즈뿐만 아니라 <br className="sm" />
                  고객의 사이즈도 측정할 수 있어요
                  <br />
                  실제 사이즈와 비교해 <br className="sm" />
                  고객에게 딱 맞는 사이즈를 추천해요
                </p>
              </div>
            </li>
            <li>
              <div className="texts-content">
                <p>
                  반품률은 줄이고
                  <br />
                  구매율은 높일 수 있어요
                </p>
                <p>
                  정확한 사이즈 추천으로
                  <br />
                  고객의 고민을 해결하고 <br className="sm" />
                  구매에 확신을 주세요
                  <br />
                  반품, 교환, CS 문제도 함께 해결될 거에요
                </p>
              </div>
              <img
                src={Service2}
                alt=""
                width={400}
                height={400}
                loading="lazy"
              />
            </li>
            <li>
              <img
                src={Service3}
                alt=""
                width={400}
                height={400}
                loading="lazy"
              />
              <div className="texts-content">
                <p>어떤 쇼핑몰에도 연동가능해요</p>
                <p>
                  어떤 호스팅 사를 이용하시던 상관없어요
                  <br />
                  셀러님의 쇼핑몰에 자연스럽게
                  <br />
                  녹아들어 더 특별한 쇼핑몰로 만들어드려요
                </p>
                <button type="button" onClick={onOpenModal}>
                  사이즈톡이 더 궁금하신가요?
                </button>
              </div>
            </li>
          </ul>
        </div>
      </section>
      {modalOpen && (
        <NewsletterModal isOpen={modalOpen} onClose={onCloseModal} />
      )}
    </>
  );
};

export default OthersServices;
