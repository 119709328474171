import './Customers.scss';

import Avatar1 from 'assets/avatar1.png';
import Avatar2 from 'assets/avatar2.png';
import Avatar3 from 'assets/avatar3.png';
import useFadeInUp from 'hooks/useFadeInUp';

const Customers = () => {
  useFadeInUp('.customers-inner h3');

  return (
    <section className="customers-sec">
      <div className="customers-inner">
        <h3 className="fade-in-up">
          클로즈잇을 이용하는
          <br />
          고객들의 이야기를 들어보세요
        </h3>
        <ul>
          <li>
            <img src={Avatar1} alt="" width={240} height={240} loading="lazy" />
            <p>
              두 세명이 담당했던 업무를
              <br />한 명이 할 수 있어 <br className="sm" />
              인건비가 절약되고 있어요
            </p>
          </li>
          <li>
            <img src={Avatar2} alt="" width={240} height={240} loading="lazy" />
            <p>
              사진만 찍으면 사이즈가 <br className="sm" />
              측정되고 <br className="lg" />
              조견표가 만들어져 <br className="sm" />
              업무가 빨리 끝나요
            </p>
          </li>
          <li>
            <img src={Avatar3} alt="" width={240} height={240} loading="lazy" />
            <p>
              클로즈잇 덕분에 생긴 <br className="sm" />
              시간으로 <br className="lg" />
              상품 셀렉에
              <br className="sm" /> 더 신경쓰니 매출이 올랐어요
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Customers;
