import gsap from 'gsap';
import { useEffect } from 'react';

const useFadeInUp = (className: string) => {
  useEffect(() => {
    gsap.to(className, {
      scrollTrigger: {
        trigger: className,
        // 70px 도달 시 애니메이션 시작
        start: '-70 bottom',
        // 애니메이션 리셋
        toggleActions: 'play none none reset',
      },
      y: 0,
      opacity: 1,
      delay: 0.3,
    });
  }, []);
};

export default useFadeInUp;
