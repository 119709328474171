import './ResetPassword.scss';

import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import TextField from 'components/common/TextField';

type FormValues = {
  email: string;
  authNumber: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

const ResetPassword = () => {
  const methods = useForm<FormValues>();
  const [step, setStep] = useState(0);

  const verifyEmail = () => {
    const { email } = methods.getValues();
    if (!email) {
      return;
    }
    setStep(1);
    methods.reset({ email });
  };

  const verifyAuthNumber = () => {
    const { email, authNumber } = methods.getValues();
    if (!email || !authNumber) {
      return;
    }
    setStep(2);
    methods.reset({ email, authNumber });
  };

  const onSubmit = () => {};

  return (
    <section className="reset-password-sec">
      <div className="reset-password-inner">
        <h2>비밀번호 재설정</h2>
        <p>가입하신 이메일 인증을 통해 비밀번호를 변경할 수 있습니다.</p>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <TextField
              required="이메일을 다시 입력해주세요."
              name="email"
              label="이메일"
              renderSuffix={() => (
                <button
                  type="button"
                  className="input-btn"
                  onClick={verifyEmail}
                >
                  이메일 인증
                </button>
              )}
            />
            {step > 0 && (
              <>
                <TextField
                  required="인증번호를 정확히 입력해 주세요."
                  name="authNumber"
                  label="인증번호"
                  type="password"
                  renderSuffix={() => (
                    <button
                      type="button"
                      className="input-btn"
                      onClick={verifyAuthNumber}
                    >
                      인증하기
                    </button>
                  )}
                />
                {step > 1 && (
                  <>
                    <TextField
                      name="newPassword"
                      label="신규 비밀번호"
                      type="password"
                      required="비밀번호가 일치하지 않습니다."
                    />
                    <TextField
                      name="newPasswordConfirmation"
                      label="신규 비밀번호 재확인"
                      type="password"
                      required="비밀번호가 일치하지 않습니다."
                    />
                  </>
                )}
              </>
            )}
            <button type="submit" disabled={step !== 2}>
              비밀번호 재설정
            </button>
          </form>
        </FormProvider>
      </div>
    </section>
  );
};

export default ResetPassword;
