import './Checkbox.scss';

import classNames from 'classnames';
import { memo } from 'react';
import { useController } from 'react-hook-form';

import { ReactComponent as CheckIcon } from 'assets/check.svg';

interface Props {
  className?: string;
  label?: string;
  name: string;
  required?: boolean | string;
  disabled?: boolean;
}

const Checkbox = ({ className, label, name, required, disabled }: Props) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules: { required },
    defaultValue: false,
  });

  const onChange = () => {
    field.onChange(!field.value);
  };

  return (
    <div className={classNames('checkbox-field', className)}>
      <label className={classNames('checkbox-container')}>
        <input
          type="checkbox"
          ref={field.ref}
          disabled={disabled}
          checked={field.value}
          onChange={onChange}
        />
        {field.value ? (
          <CheckIcon />
        ) : (
          <div className="checkbox">
            <span></span>
          </div>
        )}
        {label && <span className="label">{label}</span>}
      </label>
      {error && <span className="error-message">{error?.message}</span>}
    </div>
  );
};

export default memo(Checkbox);
